import React, { useEffect, useState } from 'react';
import './MCCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import defaultImageUrl from '../../assets/lisbethMC.png';


function getColorForProgress(progress) {
    if (progress < 33) return '#FFFFFF';
    else if (progress <= 66) return '#FFC086';
    else return '#ff7067';
}

function getProgressFromKM(km){
    const upperBound = 25000;
    return km/upperBound*100
}

const MCCard = ({ gps, s, cardNumber, cardId, km, status, cardDescription, stelNumber}) => {
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);

    const getMCImage = async (cardId) => {
        try {
            const response = await axios.get(`/api/mc/get-image/${cardId}`);
            setImageUrl(response.data.url);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    // Sets default image
    const handleImageError = () => {
        setImageUrl(defaultImageUrl);
    };

    useEffect(() => {
        if (cardId) {
            getMCImage(cardId);
        }
    }, [cardId]);

    const gpsIcon = (gps) => {
        if (gps) {return "#35b235";} else {return "#b23535";}
    };

    const sunken = (s) => {
        if (s) {return "flex";} else {return "none";}
    };
    const navigate = useNavigate();
    const goToConcreteMC = () => navigate(`/main/dashboard/mc/${cardId}`);
    const mcCardStatusColor = (status) => {
        const baseColor = '#222534';
        const tint = status === 'AKTIV' ? baseColor : (status === 'INAKTIV' ? 'rgb(178, 157, 53, 0.2)' : 'rgb(178, 53, 53, 0.2)');
        return `linear-gradient(${tint}, ${tint}), ${baseColor}`;
    };

    return (
        <div className="card" onClick={goToConcreteMC} style={{background: mcCardStatusColor(status)}}>
            <div className="card_header">
                <div className="card_header_container">
                    <div className="card_header_container_identifiers">
                        <span>Nr<span className="semicolon">:</span></span>
                        <span>Reg. nr<span className="semicolon">:</span></span>
                        <span>Stel nr<span className="semicolon">:</span></span>
                        <span>Beskrivelse<span className="semicolon">:</span></span>
                    </div>
                    <div className="card_header_container_values">
                        <span>{cardNumber}</span>
                        <span>{cardId}</span>
                        <span>{stelNumber}</span>
                        <span>{cardDescription}</span>
                    </div>
                </div>
                <div className="gps_sunken">
                    <FontAwesomeIcon icon={faLocationDot} className="gps_icon" style={{color: gpsIcon(gps)}}/>
                    <p style={{display: sunken(s), userSelect: "none"}}><b>S</b></p>
                </div>
            </div>
            <div className="card_image">
                <img src={imageUrl} alt="Bike" onError={handleImageError} loading="lazy"/>
            </div>
            <div className="card_footer">
                <div className="progress_bar_container">
                    <div className="progress_bar"
                         style={{width: `${getProgressFromKM(km)}%`, backgroundColor: getColorForProgress(getProgressFromKM(km))}}>
                    </div>
                    <span className="progress_text">{km} km</span>
                </div>
            </div>
        </div>
    );
};

export default MCCard;
