import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Shared/Header";
import "./ConcreteMC.css";
import HistoryCard from "./HistoryCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faSearch,
  faXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import SplitInner from "../Shared/SplitInner";
import defaultImageUrl from "../../assets/lisbethMC.png";
import Dropdown from "../Shared/Dropdown";
import SearchStyleButton from "../Shared/SearchStyleButton";
import Modal from "../Shared/Modal";
import NumberFormat from "react-currency-format";
import QuantBox from "../Shared/QuantBox";
import FileUpload from "../Shared/upload";
import PopUp from "../Shared/PopUp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ObjectId } from "bson";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function getColorForProgress(progress) {
  if (progress < 33) return "#FFFFFF";
  else if (progress <= 66) return "#FFC086";
  else return "#ff7067";
}

function getProgressFromKM(km) {
  const upperBound = 25000;
  return (km / upperBound) * 100;
}

const ConcreteMC = () => {
  // User Group Rights
  const { user } = useAuth();

  const canUpdatePurchaseDate = user.userLevel <= 0;
  const canUpdateStatus = user.userLevel <= 0;
  const canDeleteMc = user.userLevel <= 0;
  const canUpdateKilometerCount = user.userLevel <= 1;
  const canUpdateSunken = user.userLevel <= 1;
  const canUpdateGPS = user.userLevel <= 1;
  const canAddRepair = user.userLevel <= 1;

  // Ability to delete and edit repairs is in HistoryCard.js

  const navigate = useNavigate();

  const eventDistinguish = ["Alle", "Overførsel", "Reparation", "Status"];
  const { cardId } = useParams();
  const [bikeData, setBikeData] = useState(null);
  const [taskImages, setTaskImages] = useState([]);
  const [antal, setAntal] = useState(0);
  const [annoTitle, setAnnoTitle] = useState("");
  const [annoDescription, setAnnoDescription] = useState("");
  const [description, setDescription] = useState("");
  const [stelnr, setStelnr] = useState("");
  const [bikeDescription, setBikeDescription] = useState("");
  const [price, setPrice] = useState("");
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [rows, setRows] = useState([]);
  const [isKmPopupOpen, setIsKmPopupOpen] = useState(false);
  const [isMotPopupOpen, setIsMotPopupOpen] = useState(false);
  const [isPurchasePopupOpen, setIsPurchasePopupOpen] = useState(false);
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);
  const [selectedToDeleteMC, setSelectedToDeleteMC] = useState("");
  const [newKm, setNewKm] = useState("");
  const [newMotDate, setNewMotDate] = useState(new Date());
  const [newPurchaseDate, setNewPurchaseDate] = useState(new Date());
  const [departments1, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("Alle");
  const [imageUrl, setImageUrl] = useState(defaultImageUrl);
  const fileInputRef = useRef(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [currentInvoiceId, setCurrentInvoiceId] = useState(null);

  const [shouldCreateInvoice, setShouldCreateInvoice] = useState(false);

  const [baseParts, setBaseParts] = useState([]); // New state for base parts

  const fetchBaseParts = async () => {
    try {
      const response = await axios.get("/api/baseparts");
      setBaseParts(response.data);
    } catch (error) {
      console.error("Error fetching base parts:", error);
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await axios.get("/api/departments");
      setDepartments(response.data);
    } catch (error) {
      alert(error);
    }
  };

  const fetchBikeData = async () => {
    try {
      const response = await fetch(`/api/motorbikes/${cardId}`);
      if (response.ok) {
        const data = await response.json();
        setBikeData(data);
        setNewMotDate(new Date(data.next_mot));
      } else {
        console.error("Failed to fetch bike data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTaskImages = async () => {
    try {
      const response = await fetch(`/api/task-images/${cardId}`);
      if (response.ok) {
        const images = await response.json();
        setTaskImages(images);
      } else {
        console.error("Failed to fetch task images");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchMCImage = async () => {
    try {
      const response = await axios.get(`/api/mc/get-image/${cardId}`);
      setImageUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchBikeData();
    fetchDepartment();
    fetchMCImage();
    fetchTaskImages();
    fetchBaseParts();
  }, [cardId]);

  const getTransportPrice = (location) => {
    const department = departments1.find(
      (department) => department.name === location
    );
    return department.tripPrice;
  };

  const department_names = departments1.map(
    (departmentObj) => departmentObj.name
  );
  const department_options = [...department_names];

  const openKmPopup = () => {
    setIsKmPopupOpen(true);
  };

  const closeKmPopup = () => {
    setIsKmPopupOpen(false);
    setNewKm("");
  };

  const openMotPopup = () => {
    setIsMotPopupOpen(true);
  };

  const closeMotPopup = () => {
    setIsMotPopupOpen(false);
    setNewMotDate(new Date());
  };

  const openPurchasePopup = () => {
    setIsPurchasePopupOpen(true);
  };

  const closePurchasePopup = () => {
    setIsPurchasePopupOpen(false);
    setNewPurchaseDate(new Date());
  };

  const openTransferPopup = () => {
    setIsTransferPopupOpen(true);
  };

  const closeTransferPopup = () => {
    setIsTransferPopupOpen(false);
    setSelectedDepartment("");
  };

  const openRepairModal = () => {
    setIsEditMode(false);
    setAnnoTitle("");
    setAnnoDescription("");
    setDescription("");
    setAntal(0);
    setPrice("");
    setRows([
      {
        quantity: 1,
        description: bikeData.location_city,
        unit_price: getTransportPrice(bikeData.location_city),
      },
    ]);
    setPreviewUrl(null);
    setFile(null);
    modalRef.current.openModal();
  };

  const openEditRepairModal = async (event) => {
    setIsEditMode(true);
    setCurrentEventId(event.id);
    setAnnoTitle(event.title);
    setAnnoDescription(event.description);
    setDescription("");
    setAntal(0);
    setPrice("");
    setCurrentInvoiceId(event.invoiceId);
    const invoiceRows = await getInvoiceRows(event.invoiceId);
    if (invoiceRows.length === 0) setShouldCreateInvoice(true);
    setRows(invoiceRows);
    setPreviewUrl(getTaskImage(event.id));
    setFile(null);
    modalRef.current.openModal();
  };

  const getInvoiceRows = async (invoiceId) => {
    try {
      // Fetch the invoices from the API
      const invoicesResponse = await axios.get("/api/invoices");

      // Ensure that response.data is an array
      const invoices = await invoicesResponse.data;

      const invoice = invoices.find((invoice) => invoice._id === invoiceId);

      if (!invoice) {
        console.error(`Invoice with ID ${invoiceId} not found`);
        return [];
      }

      // Extract and return the items array from the matched invoice
      return invoice.items || [];
    } catch (error) {
      console.error("Error fetching invoices:", error);
      return [];
    }
  };

  const handleKmUpdate = async () => {
    try {
      const currentDate = new Date();
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: "kilometers_driven",
          value: newKm,
        }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          kilometers_driven: newKm,
          km_updated: currentDate,
        }));
        closeKmPopup();
        console.log("Kilometers and km_updated updated successfully");
      } else {
        console.error("Failed to update kilometers");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDateUpdate = async () => {
    try {
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: isMotPopupOpen ? "next_mot" : "purchased",
          value: isMotPopupOpen
            ? new Date(newMotDate)
            : new Date(newPurchaseDate),
        }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          [isMotPopupOpen ? "next_mot" : "purchased"]: isMotPopupOpen
            ? newMotDate
            : newPurchaseDate,
        }));
        isMotPopupOpen ? closeMotPopup() : closePurchasePopup();
        console.log(
          (isMotPopupOpen ? "Next MOT" : "Purchased") +
            " date updated successfully"
        );
      } else {
        console.error(
          "Failed to update " +
            (isMotPopupOpen ? "next MOT" : "purchased") +
            " date"
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTransferUpdate = async () => {
    const prevLocation = bikeData.location_city;
    const newLocation = selectedDepartment;
    const currentDate = new Date();
    const transferEvent = {
      id: new ObjectId(),
      title: "Overførsel",
      description: `MC overførsel fra ${prevLocation} til ${newLocation}`,
      date: currentDate,
      location: newLocation,
      location_prev: prevLocation,
      km_driven: bikeData.kilometers_driven,
      type: "Overførsel",
    };

    try {
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: "historical_events",
          value: transferEvent,
        }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          location_city: newLocation,
          historical_events: [...prevData.historical_events, transferEvent],
        }));
        closeTransferPopup();
        console.log("Location and historical events updated successfully");
      } else {
        console.error("Failed to update location and historical events");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGpsToggle = async () => {
    try {
      const newGpsValue = !bikeData.gps_activated;
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ field: "gps_activated", value: newGpsValue }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          gps_activated: newGpsValue,
        }));
        console.log("GPS status updated successfully");
      } else {
        console.error("Failed to update GPS status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoweredToggle = async () => {
    try {
      const newLoweredValue = !bikeData.lowered;
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ field: "lowered", value: newLoweredValue }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({ ...prevData, lowered: newLoweredValue }));
        console.log("Lowered status updated successfully");
      } else {
        console.error("Failed to update lowered status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddRepair = async () => {
    const invoiceId = await handleAddInvoice();

    let repId;

    if (isEditMode) repId = currentEventId;
    else repId = new ObjectId();

    const newRepair = {
      id: repId,
      title: annoTitle,
      description: annoDescription,
      date: new Date(),
      location: bikeData.location_city,
      km_driven: bikeData.kilometers_driven,
      type: "Reparation",
      isEdit: isEditMode,
      invoiceId: invoiceId,
    };

    try {
      let updatedTasks;
      if (isEditMode) {
        updatedTasks = bikeData.historical_events.map((event) =>
          event.id === repId ? newRepair : event
        );
      } else {
        updatedTasks = [...bikeData.historical_events, newRepair];
      }

      await fetchBikeData();
      setBikeData((prevData) => ({
        ...prevData,
        historical_events: updatedTasks,
      }));

      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ field: "historical_events", value: newRepair }),
      });

      if (response.ok) {
        await handleUpload("task_id", cardId, repId);

        console.log("Task added/updated successfully");
      } else {
        console.error("Failed to add/update task");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setAnnoTitle("");
    setAnnoDescription("");
    setShouldCreateInvoice(false);
    modalRef.current.closeModal();
  };

  const handleAddInvoice = async () => {
    const department = bikeData.location_city;
    const totalExVat = rows.reduce(
      (sum, item) => sum + item.quantity * item.unit_price,
      0
    );
    const vat = totalExVat * 0.25; // Assuming VAT is 25%
    const totalInclVat = totalExVat + vat;

    const newInvoice = {
      _id: currentInvoiceId,
      department,
      type: "Reparation",
      items: rows.map((item) => ({
        description: item.description,
        quantity: item.quantity,
        unit_price: item.unit_price,
        total: item.quantity * item.unit_price,
      })),
      total_ex_vat: totalExVat,
      vat: vat,
      total_incl_vat: totalInclVat,
      motorcycleReg: cardId,
    };

    try {
      let response;
      if ((shouldCreateInvoice || !isEditMode) && rows.length > 0) {
        response = await fetch("/api/add-invoice", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newInvoice),
        });
        return await response.json();
      } else if (!shouldCreateInvoice && isEditMode && rows.length > 0) {
        response = await fetch("/api/edit-invoice", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newInvoice),
        });
      } else if (currentInvoiceId) {
        response = await axios.delete(`/api/invoices/${currentInvoiceId}`);
      }

      if (!response) return currentInvoiceId;

      if (response.ok) {
        console.log(`Invoice ${isEditMode ? "edited" : "added"} successfully`);
      } else {
        console.error(`Failed to ${isEditMode ? "edit" : "add"} invoice`);
      }
      return currentInvoiceId;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpload = async (uploadType, bikeId, taskId) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", uploadType);
    formData.append("id", JSON.stringify({ bikeId, taskId }));

    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        console.log("success upload");
        await fetchTaskImages();
      } else {
        throw new Error("Failed to upload file.");
      }
    } catch (error) {
      alert("Error: " + error);
    }

    setFile(null);
    setPreviewUrl(null);
  };

  // Sets default image
  const handleImageError = () => {
    setImageUrl(defaultImageUrl);
  };

  const changeMCImage = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("type", "reg_nr");
    formData.append("id", cardId);
    formData.append("image", file);

    try {
      // Add new image
      const response = await axios.post("/api/upload", formData);

      if (response.data) {
        fetchMCImage();
      } else console.error("Something went wrong");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleFileChange = async (event, context) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      if (context === "motorcycle") {
        await changeMCImage(file);
      }
    }
  };

  const handleAddRow = () => {
    let formattedPrice = price.includes(",")
      ? price.replace(/\./g, "").replace(",", ".")
      : price;
    const normalizedPrice = parseFloat(formattedPrice);

    setRows([
      ...rows,
      {
        quantity: antal,
        description: description,
        unit_price: normalizedPrice,
      },
    ]);
    setDescription("");
    setAntal(1);
    setPrice("");
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      // Ensure latest bike data is fetched
      await fetchBikeData();

      const newEvent = {
        id: new ObjectId(),
        title: `Denne motorcykel er ${newStatus}`,
        description: `Status har ændret sig fra ${bikeData.status} til ${newStatus}`,
        date: new Date(),
        location: bikeData.location_city,
        km_driven: bikeData.kilometers_driven,
        type: "Status",
      };

      let response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ field: "historical_events", value: newEvent }),
      });

      if (!response.ok) {
        throw new Error("Failed to update historical event");
      }

      // Update status field
      response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ field: "status", value: newStatus }),
      });

      if (!response.ok) {
        throw new Error("Failed to update status");
      }

      // Ensure state is updated after successful API calls
      setBikeData({
        ...bikeData,
        status: newStatus,
        historical_events: [...bikeData.historical_events, newEvent],
      });
      console.log("Status and historical events updated successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSetActive = () => handleStatusUpdate("AKTIV");
  const handleSetInaktiv = () => handleStatusUpdate("INAKTIV");
  const handleSetSolgt = () => handleStatusUpdate("SOLGT");

  const handleSletMC = async () => {
    const formData = new FormData();
    formData.append("type", "reg_nr");
    formData.append("id", cardId);

    try {
      // Deletes the MC image
      await axios.post("/api/delete-image", formData);
      // Deletes the history event images
      formData.set("type", "events");
      await axios.post("/api/delete-image", formData);
      // Deletes the motorcycle object
      await axios.delete("/api/delete-mc", {
        data: { registration_number: selectedToDeleteMC },
      });
      // Go to the dashboard after deletion
      navigate("/main/dashboard");
    } catch (error) {
      alert(error);
    }
  };

  const updateRow = (quantity, description, unit_price) => {
    setRows((prevRows) => {
      const rowIndex = prevRows.findIndex(
        (row) => row.description === description
      );

      if (quantity <= 0) {
        return prevRows.filter((row) => row.description !== description);
      } else if (rowIndex !== -1) {
        const updatedRows = [...prevRows];
        updatedRows[rowIndex] = { quantity, description, unit_price };
        return updatedRows;
      } else {
        return [...prevRows, { quantity, description, unit_price }];
      }
    });
  };

  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const editDescriptionModalRef = useRef(null);
  const editStelnrModalRef = useRef(null);

  if (!bikeData) {
    return <div>Loading...</div>;
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "AKTIV":
        return "rgb(53, 178, 53)";
      case "INAKTIV":
        return "rgb(178, 157, 53)";
      case "SOLGT":
        return "rgb(178, 53, 53)";
      default:
        return "white";
    }
  };

  const renderStatusButtons = (status) => {
    switch (status) {
      case "AKTIV":
        return (
          <>
            <div className="mc_status">
              <button onClick={handleSetInaktiv}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span
                  style={{ color: "rgb(178, 157, 53)", fontWeight: "bold" }}
                >
                  INAKTIV
                </span>
              </button>
            </div>
            <div className="mc_transfer">
              <button onClick={handleSetSolgt}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span style={{ color: "rgb(178, 53, 53)", fontWeight: "bold" }}>
                  SOLGT
                </span>
              </button>
            </div>
          </>
        );
      case "INAKTIV":
        return (
          <>
            <div className="mc_status">
              <button onClick={handleSetActive}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span style={{ color: "rgb(53, 178, 53)", fontWeight: "bold" }}>
                  AKTIV
                </span>
              </button>
            </div>
            <div className="mc_transfer">
              <button onClick={handleSetSolgt}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span style={{ color: "rgb(178, 53, 53)", fontWeight: "bold" }}>
                  SOLGT
                </span>
              </button>
            </div>
          </>
        );
      case "SOLGT":
        return (
          <>
            <div className="mc_status">
              <button onClick={handleSetActive}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span style={{ color: "rgb(53, 178, 53)", fontWeight: "bold" }}>
                  AKTIV
                </span>
              </button>
            </div>
            <div className="mc_transfer">
              <button onClick={handleSetInaktiv}>
                <span style={{ color: "white" }}>Sæt</span>{" "}
                <span
                  style={{ color: "rgb(178, 157, 53)", fontWeight: "bold" }}
                >
                  INAKTIV
                </span>
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getTaskImage = (taskId) => {
    const taskImage = taskImages.find((image) => image.taskId == taskId);
    return taskImage ? taskImage.url : null;
  };

  const filteredTasks = bikeData.historical_events.filter((event) => {
    const titleMatch = event.title
      .toLowerCase()
      .includes(searchTitle.toLowerCase());

    if (selectedEvent === "Alle") {
      return titleMatch;
    } else {
      return titleMatch && event.type === selectedEvent;
    }
  });

  const handleEditDescription = async () => {
    try {
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: "description",
          value: bikeDescription,
        }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          description: bikeDescription,
        }));
        editDescriptionModalRef.current.closeModal();
        console.log("Description updated successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleEditStelnr = async () => {
    try {
      const response = await fetch(`/api/motorbikes/${cardId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: "stelnr",
          value: stelnr,
        }),
      });

      if (response.ok) {
        setBikeData((prevData) => ({
          ...prevData,
          stelnr: stelnr,
        }));
        editStelnrModalRef.current.closeModal();
        console.log("Stel nr. updated successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main_inner">
      <Header title={"Oversigt"}>
        <div className="data_container">
          <p>
            <b>Motorcykel nr:</b> {bikeData.identification_number}
          </p>
          <p>
            <b>Reg. nummer:</b> {bikeData.registration_number}
          </p>
          <p style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <b>Beskrivelse:</b> {bikeData.description}
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{
                color: "white",
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
              onClick={() => editDescriptionModalRef.current.openModal()}
            />
          </p>
          <p style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <b>Stel nr.</b> {bikeData.stelnr}
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{
                color: "white",
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
              onClick={() => editStelnrModalRef.current.openModal()}
            />
          </p>
        </div>
        <div className="data_container">
          <p>
            <b>Historik</b>
          </p>
        </div>
      </Header>
      <div className="main_inner_content">
        <Modal
          ref={modalRef}
          title={isEditMode ? "Opdater reparation" : "Tilføj reperation"}
        >
          <div className="add_rep_container">
            <div className="add_rep_left">
              <div className="add_rep_header">
                <span>Diverse dele</span>
              </div>
              <div className="add_rep_desc">
                <span>Beskrivelse</span>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Skriv beskrivelse..."
                  className="search_input"
                />
              </div>
              <div className="add_rep_price">
                <div className="add_rep_price_input">
                  <span>Pris</span>
                  <NumberFormat
                    value={price}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setPrice(value);
                    }}
                    placeholder="Skriv stk. pris..."
                    className="search_input"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"DKK "}
                    allowNegative={false}
                    isNumericString={true}
                  />
                </div>
                <div className="add_rep_price_number">
                  <span>Antal</span>
                  <div
                    id="hoverfix"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="text"
                      value={antal}
                      onChange={(e) => setAntal(parseInt(e.target.value) || 0)}
                      className="search_input"
                      placeholder="0"
                    />
                    <button
                      className="search_input"
                      onClick={() => setAntal(antal + 1)}
                    >
                      +
                    </button>
                    <button
                      className="search_input"
                      onClick={() => setAntal(antal - 1)}
                    >
                      -
                    </button>
                  </div>
                </div>
                <div className="add_rep_price_interact">
                  <button onClick={handleAddRow}>TILFØJ ELEMENT</button>
                </div>
              </div>
              <div className="add_rep_invoice">
                <table>
                  <thead>
                    <tr>
                      <th>Stk.</th>
                      <th>Beskrivelse</th>
                      <th>Stk. pris</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <FontAwesomeIcon
                            className="add_rep_delete_item_invoice"
                            icon={faXmark}
                            onClick={() => handleRemoveRow(index)}
                            style={{ cursor: "pointer" }}
                          />
                          <p>{row.quantity}</p>
                        </td>
                        <td>{row.description}</td>
                        <td>
                          <NumberFormat
                            value={row.unit_price}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"DKK "}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="add_rep_right">
              <div className="add_rep_header">
                <span>Basis dele</span>
              </div>
              <div className="add_rep_right_basis">
                {baseParts.map((part, index) => {
                  const initialQuantity = rows
                    ? rows.find((item) => item.description === part.description)
                        ?.quantity || 0
                    : 0;
                  return (
                    <div key={index} className="right_basis_item">
                      <p>{part.description}</p>
                      <QuantBox
                        update={(newQuantity) =>
                          updateRow(newQuantity, part.description, part.price)
                        }
                        initialValue={initialQuantity}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="add_rep_right_annotation">
                <div className="annotation_title">
                  <div className="annotation_title_container">
                    <p>Reperationstitel</p>
                    <input
                      type="text"
                      value={annoTitle}
                      onChange={(e) => setAnnoTitle(e.target.value)}
                      placeholder="Skriv titel..."
                      className="search_input"
                    />
                  </div>
                  <div id="uploadfix">
                    <FileUpload
                      onFileChange={handleFileChange}
                      previewUrl={previewUrl}
                    />
                  </div>
                </div>
                <div className="annotation_decription">
                  <p>Reperationsbeskrivelse</p>
                  <textarea
                    value={annoDescription}
                    onChange={(e) => setAnnoDescription(e.target.value)}
                    placeholder="Beskriv hændelse..."
                    className="search_input"
                    rows="1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="reperation_submit_container">
            <button onClick={handleAddRepair}>
              {isEditMode ? "Opdater reperation" : "Tilføj reperation"}
            </button>
          </div>
        </Modal>
        <SplitInner>
          <div className="split_inner_container_split_mc">
            <img
              src={imageUrl}
              alt="Motorcycle"
              onError={handleImageError}
              onClick={handleImageClick}
              loading="lazy"
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => handleFileChange(event, "motorcycle")}
            />
            <div className="split_inner_container_split_mc_data">
              <div className="mc_data_location_gps_sunken">
                <div className="mc_data_status_location">
                  <p>Lokation: {bikeData.location_city}</p>
                  <p>
                    Status:{" "}
                    <span
                      style={{
                        color: getStatusColor(bikeData.status),
                        fontWeight: "bold",
                      }}
                    >
                      {bikeData.status}
                    </span>
                  </p>
                </div>
                <div className="mc_data_icons">
                  <p
                    className="mc_data_sunken"
                    style={{
                      color: bikeData.lowered
                        ? "rgb(79, 202, 49)"
                        : "rgb(178, 53, 53)",
                      cursor: "pointer",
                    }}
                    onClick={canUpdateSunken ? handleLoweredToggle : undefined}
                  >
                    <b style={{ userSelect: "none" }}>S</b>
                  </p>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="gps_icon"
                    style={{
                      color: bikeData.gps_activated
                        ? "rgb(53, 178, 53)"
                        : "rgb(178, 53, 53)",
                      cursor: "pointer",
                    }}
                    onClick={canUpdateGPS ? handleGpsToggle : undefined}
                  />
                </div>
              </div>
              <div className="mc_data_survey_acquisition">
                <div className="mc_survey">
                  <p>Næste syn:</p>
                  <p>{formatDate(bikeData.next_mot)}</p>
                  <div
                    className="edit_survey"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "10px",
                      width: "fit-content",
                      height: "fit-content",
                      padding: "5px",
                    }}
                    onClick={openMotPopup}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div>
                <div className="mc_acquisition">
                  <p>Tilegnelse:</p>
                  <p>{formatDate(bikeData.purchased)}</p>
                  {canUpdatePurchaseDate && (
                    <div
                      className="edit_survey"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        width: "fit-content",
                        height: "fit-content",
                        padding: "5px",
                      }}
                      onClick={openPurchasePopup}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mc_data_distance">
                <div className="mc_data_distance_inner">
                  <div className="mc_data_distance_inner_text">
                    <p>Kilometertal</p>
                    {canUpdateKilometerCount && (
                      <div
                        className="edit_survey"
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          padding: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={openKmPopup}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginBottom: "5px",
                          }}
                        />
                      </div>
                    )}
                    <span>
                      pr.{" "}
                      {bikeData.km_updated ? (
                        <span>{formatDate(bikeData.km_updated)}</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="progress_bar_container"
                    style={{ width: "94%", marginLeft: "3%", top: "0px" }}
                  >
                    <div
                      className="progress_bar"
                      style={{
                        height: "30px",
                        width: `${getProgressFromKM(
                          bikeData.kilometers_driven
                        )}%`,
                        backgroundColor: getColorForProgress(
                          getProgressFromKM(bikeData.kilometers_driven)
                        ),
                      }}
                    ></div>
                    <span className="progress_text">
                      {bikeData.kilometers_driven} km
                    </span>
                  </div>
                </div>
              </div>
              <div className="mc_data_interact">
                {canUpdateStatus && (
                  <div className="mc_data_interact_status">
                    {renderStatusButtons(bikeData.status)}
                  </div>
                )}
                <div className="mc_data_interact_status">
                  {canDeleteMc && (
                    <div className="mc_status">
                      <button
                        style={{
                          color: "rgb(178, 53, 53)",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setSelectedToDeleteMC(bikeData.registration_number);
                          modalRef2.current.openModal();
                        }}
                      >
                        SLET <span style={{ color: "white" }}>MC</span>
                      </button>
                    </div>
                  )}
                  <div className="mc_transfer">
                    <button onClick={openTransferPopup}>
                      Overfør <span style={{ fontWeight: "bold" }}>MC</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="split_inner_container_split_history">
            <div
              className="history_search_container"
              style={{ marginBottom: "20px" }}
            >
              <div
                className="search_container"
                style={{ marginLeft: "0px", height: "60px", flex: "1" }}
              >
                <FontAwesomeIcon icon={faSearch} className="search_icon" />
                <input
                  type="text"
                  placeholder="Søg efter titel..."
                  className="search_input"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
                <Dropdown
                  init={"Hændelse"}
                  options={eventDistinguish}
                  onSelect={(option) => setSelectedEvent(option)}
                />
              </div>
              {canAddRepair && (
                <div
                  className="search_container"
                  style={{ marginLeft: "10px", height: "60px" }}
                  onClick={openRepairModal}
                >
                  <SearchStyleButton title={"Tilføj reperation"} />
                </div>
              )}
            </div>
            <div className="history_card_container">
              {filteredTasks
                .slice()
                .reverse()
                .map((task, index) => (
                  <HistoryCard
                    key={index}
                    header={task.title}
                    description={task.description}
                    loca={task.location}
                    prevLoca={task.location_prev}
                    date={task.date}
                    imgpath={getTaskImage(task.id)}
                    currentKM={task.km_driven}
                    id={task.id}
                    motorbikeId={bikeData._id}
                    setBikeData={setBikeData}
                    bikeRegNumber={bikeData.registration_number}
                    onEdit={() => openEditRepairModal(task)} // Pass the task to the modal
                    type={task.type}
                    invoiceId={task.invoiceId}
                  />
                ))}
            </div>
          </div>
        </SplitInner>
      </div>
      <PopUp
        isOpen={isKmPopupOpen}
        onClose={closeKmPopup}
        onSubmit={handleKmUpdate}
        title="Opdatér kilometer"
      >
        <input
          value={newKm}
          onChange={(e) => setNewKm(e.target.value)}
          placeholder="KM"
          className="search_input"
        />
      </PopUp>
      <PopUp
        isOpen={isMotPopupOpen}
        onClose={closeMotPopup}
        onSubmit={handleDateUpdate}
        title="Opdatér næste synsdato"
      >
        <DatePicker
          selected={newMotDate}
          onChange={(date) => setNewMotDate(new Date(date))}
          dateFormat="dd/MM/yyyy"
          className="search_input"
        />
      </PopUp>
      <PopUp
        isOpen={isPurchasePopupOpen}
        onClose={closePurchasePopup}
        onSubmit={handleDateUpdate}
        title="Opdatér tilegnelse"
      >
        <DatePicker
          selected={newPurchaseDate}
          onChange={(date) => setNewPurchaseDate(new Date(date))}
          dateFormat="dd/MM/yyyy"
          className="search_input"
        />
      </PopUp>
      <PopUp
        isOpen={isTransferPopupOpen}
        onClose={closeTransferPopup}
        onSubmit={handleTransferUpdate}
        title="Overfør MC"
      >
        <Dropdown
          init={selectedDepartment || "Vælg afdeling"}
          options={department_options}
          onSelect={(option) => setSelectedDepartment(option)}
        />
      </PopUp>
      <Modal
        ref={modalRef2}
        title={"Er du sikker på sletning af " + selectedToDeleteMC + "?"}
      >
        <div id="modal_inner_fix">
          <div>
            <div className="reperation_submit_container">
              <button
                onClick={handleSletMC}
                style={{ color: "rgb(178, 53, 53)" }}
              >
                SLET MC
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ref={editDescriptionModalRef}
        title={"Ændring af motorcyklens beskrivelse"}
      >
        <div id="modal_inner_fix">
          {/* Add onSubmit handler */}
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevents the default form behavior
              handleEditDescription(); // Call your form submission logic
            }}
          >
            <label className="bike_desc_title">Beskrivelse</label>
            <input // should I use textarea instead? test
              type="text"
              value={bikeDescription} // Make sure this is tied to the correct state
              onChange={(e) => setBikeDescription(e.target.value)} // Update the correct state
              placeholder="Skriv beskrivelse..."
              className="search_input"
            />
            {/* Change button type to "submit" */}
            <div className="reperation_submit_container">
              <button type="submit">
                Opdatér Beskrivelse
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ref={editStelnrModalRef}
        title={"Ændring af motorcyklens stel nummer"}
      >
        <div id="modal_inner_fix">
          {/* Add onSubmit handler */}
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevents the default form behavior
              handleEditStelnr(); // Call your form submission logic
            }}
          >
            <label className="bike_desc_title">Stel Nummer</label>
            <input
              type="text"
              value={stelnr} // Make sure this is tied to the correct state
              onChange={(e) => setStelnr(e.target.value)} // Update the correct state
              placeholder="Skriv stel nr. ..."
              className="search_input"
            />
            {/* Change button type to "submit" */}
            <div className="reperation_submit_container">
              <button type="submit">
                Opdatér Stel nr.
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ConcreteMC;
