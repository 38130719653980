import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";
import "./ConcreteMC.css";
import Header from "../Shared/Header";
import Dropdown from "../Shared/Dropdown";
import MCCard from "./MCCard";
import SearchStyleButton from "../Shared/SearchStyleButton";
import axios from "axios";
import Modal from "../Shared/Modal";
import DatePicker from "react-datepicker";
import FileUpload from "../Shared/upload";
import { ObjectId } from "bson";
import NumberFormat from "react-currency-format";
import { useAuth } from "../../Auth/AuthContext";

const Dashboard = () => {
  // User Group Rights
  const { user } = useAuth();

  const canDeleteDepartment = user.userLevel <= 0;
  const canAddDepartment = user.userLevel <= 0;
  const canAddMc = user.userLevel <= 0;
  // Add ability to only view a specific department here

  const [motorbikes, setMotorbikes] = useState([]);
  const [departments1, setDepartments] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("Alle");
  const [searchTerm, setSearchTerm] = useState("");
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const modalRef3 = useRef(null);
  const [motDate, setMotDate] = useState(new Date());
  const [createDate, setCreateDate] = useState(new Date());
  const [bikeDescription, setBikeDescription] = useState("");
  const [mcnum, setMcnum] = useState("");
  const [stelnr, setStelnr] = useState("");
  const [mcreg, setMcreg] = useState("");
  const [mcdriven, setMcdriven] = useState("");
  const [mcSelectedDepartment, setMcSelectedDepartment] = useState("Afdeling");
  const [departmentToDelete, setDepartmentToDelete] = useState("");
  const [gps, setGps] = useState(false);
  const [sunk, setSunk] = useState(false);
  const [file, setFile] = useState();
  const [tripPrice, setTripPrice] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDateChange = (date, type) => {
    if (type === "syn") {
      setMotDate(date);
    } else if (type === "tilegn") {
      setCreateDate(date);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/motorbikes");
      setMotorbikes(response.data);
    } catch (error) {
      alert(error);
    }
    try {
      const response = await axios.get("/api/departments");
      setDepartments(response.data);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const department_names = departments1.map(
    (departmentObj) => departmentObj.name
  );

  const departmentsWithMotorbikesDict = department_names.map(
    (department_name) => {
      const motorbikesInDepartment = motorbikes.filter(
        (motorbike) => motorbike.location_city === department_name
      );
      return {
        department_name,
        motorbikes: motorbikesInDepartment,
      };
    }
  );

  let options = ["Alle", ...department_names];
  let options2 = [...department_names];

  const handleGpsToggle = () => {
    setGps(!gps);
  };

  const handleSunkToggle = () => {
    setSunk(!sunk);
  };

  const filteredDepartments =
    selectedDepartment === "Alle"
      ? departmentsWithMotorbikesDict
      : departmentsWithMotorbikesDict.filter(
          (departmentObj) =>
            departmentObj.department_name === selectedDepartment
        );

  const filteredMotorbikes = filteredDepartments.map((departmentObj) => {
    return {
      ...departmentObj,
      motorbikes: departmentObj.motorbikes.filter((motorbike) =>
        motorbike.registration_number
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ),
    };
  });

  const getBikesInDepartment = (departmentName) => {
    return motorbikes.filter((bike) => bike.location_city === departmentName);
  };

  const handleDeleteTransfer = async () => {
    const prevLocation = departmentToDelete;
    const newLocation = "Andre";
    const currentDate = new Date().toISOString();

    const bikesInDeletedDepartment = getBikesInDepartment(departmentToDelete);

    const updatePromises = bikesInDeletedDepartment.map(async (bike) => {
      try {
        const transferEvent = {
          id: new ObjectId().toString(),
          title: "Overførsel",
          description: `MC slette-overførsel fra ${prevLocation} til ${newLocation}`,
          date: currentDate,
          location: newLocation,
          location_prev: prevLocation,
          km_driven: bike.kilometers_driven,
        };
        const response = await fetch(
          `/api/motorbikes/${bike.registration_number}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              field: "location_city",
              value: newLocation,
              historical_event: transferEvent,
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to update location and historical events");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    });

    await Promise.all(updatePromises);
  };

  const deleteDepartment = async () => {
    try {
      // Transfers motorbikes to "Andre" department
      await handleDeleteTransfer();
      // Deletes the department
      await axios.delete("/api/delete-department", {
        data: { department_name: departmentToDelete },
      });
      fetchData();
      setDepartmentToDelete("");
      modalRef3.current.closeModal();
    } catch (error) {
      alert(error);
    }
  };

  const handleDeleteDepartment = (department_name) => {
    setDepartmentToDelete(department_name);
    modalRef3.current.openModal();
  };

  const departmentElements = filteredMotorbikes.map((departmentObj) => (
    <div
      className="main_inner_content_dashboard_cardcontainer"
      key={departmentObj.department_name}
    >
      <div className="deptTitleDelete">
        <p>{departmentObj.department_name}</p>
        {departmentObj.department_name !== "Andre" && canDeleteDepartment && (
          <FontAwesomeIcon
            icon={faXmark}
            style={{ color: "white", cursor: "pointer" }}
            onClick={() =>
              handleDeleteDepartment(departmentObj.department_name)
            }
          />
        )}
      </div>
      <div className="main_inner_content_dashboard_cardcontainer_department">
        {departmentObj.motorbikes.map((motorbike) => (
          <MCCard
            key={motorbike.registration_number}
            cardNumber={motorbike.identification_number}
            cardId={motorbike.registration_number}
            km={motorbike.kilometers_driven}
            gps={motorbike.gps_activated}
            s={motorbike.lowered}
            dept={departmentObj.department}
            status={motorbike.status}
            cardDescription={motorbike.description}
            stelNumber={motorbike.stelnr}
          />
        ))}
      </div>
    </div>
  ));

  const handleSubmitOfMC = async () => {
    if (mcSelectedDepartment === "Afdeling") {
      alert("Vælg en afdeling");
      return;
    }
    if (isNullOrWhitespace(mcreg)) {
      alert("Angiv MC-registreringsnummer");
      return;
    }
    if (isNullOrWhitespace(mcnum)) {
      alert("Angiv MC-Nummer");
      return;
    }

    const km_driven = isNullOrWhitespace(mcdriven) ? 0 : mcdriven;

    const newMotorbike = {
      purchased: createDate,
      description: bikeDescription,
      location_city: mcSelectedDepartment,
      next_mot: motDate,
      kilometers_driven: km_driven,
      gps_activated: gps,
      lowered: sunk,
      historical_events: [],
      status: "AKTIV",
      km_updated: new Date(),
      registration_number: mcreg,
      identification_number: mcnum,
      stelnr: stelnr,
    };

    try {
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("type", "reg_nr");
        formData.append("id", mcreg);

        await axios.post("/api/upload", formData);
      }
      await axios.post("/api/add-motorbike", newMotorbike);
      fetchData();
      modalRef.current.closeModal();
      setMcSelectedDepartment("Afdeling");
    } catch (error) {
      console.error("Error adding motorbike:", error);
      alert("Failed to add motorbike");
    }
  };

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  const handleSubmitOfDepartment = async () => {
    if (isNullOrWhitespace(newDepartmentName)) {
      alert("Skriv et navn på afdelingen");
      return;
    }

    const newDepartment = {
      name: newDepartmentName,
      tripPrice: parseFloat(tripPrice),
    };

    try {
      await axios.post("/api/add-department", newDepartment);
      fetchData();
      modalRef2.current.closeModal();
    } catch (error) {
      console.error("Error adding department:", error);
      alert("Failed to add department");
    }
    setNewDepartmentName("");
  };

  return (
    <div className="main_inner">
      <Header title={"Oversigt"}>
        <div className="search_container">
          <FontAwesomeIcon icon={faSearch} className="search_icon" />
          <input
            type="text"
            placeholder="Søg efter reg. nr..."
            className="search_input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Dropdown
            init={"Alle"}
            options={options}
            style={{ width: "200px" }}
            onSelect={(option) => {
              setSelectedDepartment(option);
            }}
          />
        </div>
        {canAddDepartment && (
          <div
            className="search_container"
            style={{ marginLeft: "10px", height: "60px" }}
            onClick={() => {
              modalRef2.current.openModal();
            }}
          >
            <SearchStyleButton title={"Tilføj Afdeling"} />
          </div>
        )}
        {canAddMc && (
          <div
            className="search_container"
            style={{ marginLeft: "10px", height: "60px" }}
            onClick={() => {
              modalRef.current.openModal();
            }}
          >
            <SearchStyleButton title={"Tilføj MC"} />
          </div>
        )}
      </Header>
      <div className="main_inner_content">
        <Modal ref={modalRef} title={"Tilføj MC"}>
          <div id="modal_inner_fix">
            <div>
              <div>
                <div className="add_mc_reg">
                  <span>Registrerings nummer</span>
                  <div className="search_container">
                    <input
                      type="text"
                      placeholder="Skriv reg. nr..."
                      className="search_input"
                      value={mcreg}
                      onChange={(e) => setMcreg(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add_mc_number">
                  <span>Nummer</span>
                  <div className="search_container">
                    <input
                      type="number"
                      placeholder="Skriv det ønskede nummer..."
                      className="search_input"
                      value={mcnum}
                      onChange={(e) => setMcnum(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add_mc_number">
                  <span>Stel nr.</span>
                  <div className="search_container">
                    <input
                      type="text"
                      placeholder="Skriv det ønskede stel nummer..."
                      className="search_input"
                      value={stelnr}
                      onChange={(e) => setStelnr(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add_mc_number">
                  <span>Beskrivelse</span>
                  <textarea
                    value={bikeDescription}
                    onChange={(e) => setBikeDescription(e.target.value)}
                    placeholder="Skriv beskrivelse..."
                    className="search_input"
                    rows="10"
                    style={{ paddingLeft: "8px", height: "13.5vh" }}
                  />
                </div>
              </div>
              <div>
                <div className="date_gps_sunken_container">
                  <div className="add_mc_date_km_dept">
                    <div className="search_container">
                      <div className="add_mc_datepicker">
                        <p>Tilegnelse</p>
                        <DatePicker
                          selected={createDate}
                          onChange={(date) => handleDateChange(date, "tilegn")}
                          dateFormat="dd/MM/yyyy"
                          className="search_input"
                        />
                      </div>
                      <div className="add_mc_datepicker">
                        <p>Næste syn</p>
                        <DatePicker
                          selected={motDate}
                          onChange={(date) => handleDateChange(date, "syn")}
                          dateFormat="dd/MM/yyyy"
                          className="search_input"
                        />
                      </div>
                    </div>
                    <div className="add_mc_driven_dept">
                      <div className="search_container">
                        <div className="add_mc_driven">
                          <p>KM kørt</p>
                          <input
                            type="number"
                            placeholder="KM..."
                            className="search_input"
                            value={mcdriven}
                            onChange={(e) => setMcdriven(e.target.value)}
                          />
                        </div>
                        <div className="add_mc_dept">
                          <p>Startafdeling</p>
                          <Dropdown
                            init={"Afdeling"}
                            options={options2}
                            style={{ width: "120px" }}
                            onSelect={(option) => {
                              setMcSelectedDepartment(option);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add_mc_gps_sunken">
                    <div className="add_mc_gps_sunken_gpsstatus">
                      <p>GPS</p>
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="gps_icon"
                        style={{
                          color: gps ? "rgb(53, 178, 53)" : "rgb(178, 53, 53)",
                          cursor: "pointer",
                        }}
                        onClick={handleGpsToggle}
                      />
                    </div>
                    <div className="add_mc_gps_sunken_sunkenstatus">
                      <p>Sænket </p>
                      <p
                        id="add_rep_mc_sunken"
                        className="gps_icon"
                        style={{
                          fontSize: "30px",
                          color: sunk ? "rgb(53, 178, 53)" : "rgb(178, 53, 53)",
                          cursor: "pointer",
                        }}
                        onClick={handleSunkToggle}
                      >
                        S
                      </p>
                    </div>
                  </div>
                </div>
                <div className="add_mc_pic">
                  <FileUpload
                    onFileChange={handleFileChange}
                    previewUrl={previewUrl}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="reperation_submit_container" style={{alignItems: "center"}}>
                <button onClick={handleSubmitOfMC} style={{width: "60%"}}>TILFØJ MC</button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal ref={modalRef2} title={"Tilføj Afdeling"}>
          <div id="modal_inner_fix">
            <div className="add_afdeling">
              <span>Afdelingens Navn</span>
              <div className="search_container">
                <input
                  type="text"
                  placeholder="Skriv afdelingens navn..."
                  className="search_input"
                  value={newDepartmentName}
                  onChange={(e) => setNewDepartmentName(e.target.value)}
                />
              </div>
            </div>

            <div className="add_rep_price_input">
              <span>{"Tur Pris"}</span>
              <NumberFormat
                value={tripPrice}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setTripPrice(value);
                }}
                placeholder="Skriv tur pris..."
                className="search_input"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"DKK "}
                allowNegative={false}
                isNumericString={true}
              />
            </div>

            <div>
              <div className="reperation_submit_container">
                <button onClick={handleSubmitOfDepartment}>
                  TILFØJ AFDELING
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          ref={modalRef3}
          title={"Er du sikker på at slette " + departmentToDelete + "?"}
        >
          <div id="modal_inner_fix">
            <div>
              <div className="reperation_submit_container">
                <button
                  onClick={deleteDepartment}
                  style={{ color: "rgb(178, 53, 53)" }}
                >
                  SLET AFDELING
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {departmentElements}
      </div>
    </div>
  );
};

export default Dashboard;
