import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../Shared/Header";
import SplitInner from "../Shared/SplitInner";
import Excel from "../Shared/Excel";
import './Invoices.css';
import DatePicker from "react-datepicker";
import Dropdown from "../Shared/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import SearchStyleButton from "../Shared/SearchStyleButton";
import NumberFormat from "react-currency-format";
import axios from 'axios';
import InvoicePdf from './InvoicePDF';
import Modal from "../Shared/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import QuantBox from "../Shared/QuantBox";

// Function to get the first day of the current year
const getFirstDayOfYear = () => {
    const now = new Date();
    return new Date(now.getFullYear(), 0, 1);
};

// Function to get the last day of the current year
const getLastDayOfYear = () => {
    const now = new Date();
    return new Date(now.getFullYear(), 11, 31);
};

const Invoices = () => {
    const navigate = useNavigate();
    const { invoiceNumber } = useParams();

    const [invoices, setInvoices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState('Alle');

    // Filtering date
    const [startDate, setStartDate] = useState(getFirstDayOfYear());
    const [endDate, setEndDate] = useState(getLastDayOfYear());

    // Invoice addition/updating/removal
    const modalRef = useRef(null);
    const [antal, setAntal] = useState(0);
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [baseParts, setBaseParts] = useState([]); // New state for base parts
    const [rows, setRows] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [invoiceAddEditDepartment, setInvoiceAddEditDepartment] = useState('');

    // Slet af faktura
    const sletFakturaModalRef = useRef(null);
    const [invoiceToDelete, setInvoiceToDelete] = useState('');

    const fetchBaseParts = async () => {
        try {
            const response = await axios.get('/api/baseparts');
            setBaseParts(response.data);
        } catch (error) {
            console.error('Error fetching base parts:', error);
        }
    };

    const fetchInvoices = async () => {
        try {
            const invoiceResponse = await axios.get('/api/invoices');
            setInvoices(invoiceResponse.data);
        } catch {
            alert("Fetching the invoices failed.");
        }
    };

    const fetchDepartments = async () => {
        try {
            const departmentResponse = await axios.get('/api/departments');
            setDepartments(departmentResponse.data);
        } catch {
            alert("Fetching the departments failed.");
        }
    };

    const departmentNames = departments.map(department => department.name);

    const fetchAllData = () => {
        fetchInvoices();
        fetchDepartments();
        fetchBaseParts();
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        if (invoiceNumber) {
            const invoice = invoices.find(inv => inv._id === invoiceNumber);
            if (invoice != null) {
                setSelectedInvoice(invoice);
            }
        } else {
            setSelectedInvoice(null);
        }
    }, [invoiceNumber, invoices]);

    const columnsLeft = [
        { title: 'Faktura #', type: 'id' },
        { title: 'Afdeling', type: 'text' },
        { title: 'Hændelsestype', type: 'text' },
        { title: 'Dato', type: 'text' }
    ];

    const columnsRight = [
        { title: 'Stk.', type: 'text' },
        { title: 'Beskrivelse', type: 'text' },
        { title: 'Stk. pris', type: 'currency' },
        { title: 'Total', type: 'currency' }
    ];

    const rowsRight = selectedInvoice ? selectedInvoice.items.map(item => [
        item.quantity,
        item.description,
        item.unit_price,
        item.total
    ]) : [];

    const normalizeDate = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const filteredInvoices = invoices.filter(invoice => {
        const invoiceDate = normalizeDate(new Date(invoice.date));
        const isWithinPeriod = invoiceDate >= startDate && invoiceDate <= endDate;

        const isSelectedDepartment = selectedDepartment === 'Alle' || invoice.department === selectedDepartment;

        const isWithinSortingParams = isSelectedDepartment && isWithinPeriod;

        return isWithinSortingParams;
    });

    const rowsLeft = filteredInvoices.map(invoice => [
        invoice._id,
        invoice.department,
        invoice.type,
        new Date(invoice.date).toLocaleDateString('en-GB')
    ]);

    const handleDateChange = (date, type) => {
        if (type === 'start') {
            setStartDate(date);
        } else if (type === 'end') {
            setEndDate(date);
        }
    };

    const accumsTextRef = useRef(null);
    const accumsActualRef = useRef(null);
    const addRepInvoiceRef = useRef(null);
    const invoicesLeftRef = useRef(null);
    const invoicesRightDataRef = useRef(null);

    const alignRightTable = () => {
        const invoicesLeft = invoicesLeftRef.current;
        const invoicesRightData = invoicesRightDataRef.current;
        const addRepInvoice = addRepInvoiceRef.current;

        if (invoicesLeft && invoicesRightData && addRepInvoice) {
            const rightRect = invoicesRightData.getBoundingClientRect();
            const invoicesRect = invoicesLeft.getBoundingClientRect();
            const tbody = addRepInvoice.querySelector('tbody');

            if (tbody) {
                const maxHeight = invoicesRect.height - (rightRect.height + 40 + 29);
                tbody.style.maxHeight = `${maxHeight}px`;
                console.log('Calculated max-height:', maxHeight);
            }
        }
    };

    const alignAccums = () => {
        const addRepInvoice = addRepInvoiceRef.current;
        const accumsText = accumsTextRef.current;
        const accumsActual = accumsActualRef.current;

        if (addRepInvoice && accumsText && accumsActual) {
            const headerCells = addRepInvoice.querySelectorAll('thead th');
            if (headerCells.length >= 2) {
                const secondLastCol = headerCells[headerCells.length - 2].getBoundingClientRect();
                const lastCol = headerCells[headerCells.length - 1].getBoundingClientRect();
                const containerRect = addRepInvoice.getBoundingClientRect();

                accumsText.style.left = `${secondLastCol.left - containerRect.left + 15}px`;
                accumsText.style.top = `${secondLastCol.top - containerRect.top - 8}px`;

                accumsActual.style.left = `${lastCol.left - containerRect.left + 15}px`;
                accumsActual.style.top = `${lastCol.top - containerRect.top - 8}px`;
            }
        }
    };

    useEffect(() => {
        alignRightTable();
        alignAccums();
        window.addEventListener('resize', alignAccums);
        return () => {
            window.removeEventListener('resize', alignRightTable);
            window.removeEventListener('resize', alignAccums);
        };
    }, [selectedInvoice]);

    const handleRowClick = (row) => {
        navigate(`/main/invoices/${row[0]}`);
    };

    const deleteInvoice = async () => {
        const invoiceId = selectedInvoice._id;
        const response = await axios.delete(`/api/invoices/${invoiceId}`);
        if (response.status !== 200) throw new Error("Failed to delete invoice");
        console.log("Invoice has been deleted");

        sletFakturaModalRef.current.closeModal();
        fetchInvoices();
        setSelectedInvoice(null);
    };

    const exportInvoice = () => {
        if (!selectedInvoice) {
            console.error("No selected invoice to export.");
            return;
        }

        const generatePdf = InvoicePdf(selectedInvoice);
        generatePdf();
    };

    const updateRow = (quantity, description, unit_price) => {
        setRows(prevRows => {
            const rowIndex = prevRows.findIndex(row => row.description === description);

            if (quantity <= 0) {
                return prevRows.filter(row => row.description !== description);
            } else if (rowIndex !== -1) {
                const updatedRows = [...prevRows];
                updatedRows[rowIndex] = { quantity, description, unit_price };
                return updatedRows;
            } else {
                return [...prevRows, { quantity, description, unit_price }];
            }
        });
    };

    const handleRemoveRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleAddRow = () => {
        let formattedPrice = price.includes(',') ? price.replace(/\./g, '').replace(',', '.') : price;
        const normalizedPrice = parseFloat(formattedPrice);

        setRows([
            ...rows,
            { quantity: antal, description: description, unit_price: normalizedPrice }
        ]);
        setDescription('');
        setAntal(1);
        setPrice('');
    };

    const handleAddInvoice = async () => {
        const department = invoiceAddEditDepartment;
        if (department === "Afdeling") {
            alert("Vælg en afdeling!");
            return;
        }
        const totalExVat = rows.reduce((sum, item) => sum + item.quantity * item.unit_price, 0);
        const vat = totalExVat * 0.25; // Assuming VAT is 25%
        const totalInclVat = totalExVat + vat;
        const invoiceType = (isEditMode ? selectedInvoice.type : "Andet");

        const newInvoice = {
            _id: (selectedInvoice ? selectedInvoice._id : undefined),
            department,
            type: invoiceType,
            items: rows.map(item => ({
                description: item.description,
                quantity: item.quantity,
                unit_price: item.unit_price,
                total: (item.quantity * item.unit_price)
            })),
            total_ex_vat: totalExVat,
            vat: vat,
            total_incl_vat: totalInclVat,
            motorcycleReg: ""
        };

        try {
            let response;
            if (!isEditMode && rows.length > 0) {
                response = await fetch('/api/add-invoice', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newInvoice)
                });
            } else if (isEditMode && rows.length > 0) {
                response = await fetch('/api/edit-invoice', {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newInvoice)
                });
            } else {
                response = await axios.delete(`/api/invoices/${selectedInvoice._id}`);
            }

            if (response.ok) {
                console.log(`Invoice ${isEditMode ? "edited" : "added"} successfully`);
            } else {
                console.error(`Failed to ${isEditMode ? "edit" : "add"} invoice`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        fetchInvoices();
        modalRef.current.closeModal();
    };

    const openInvoiceModal = () => {
        setIsEditMode(false);
        setDescription('');
        setAntal(0);
        setPrice('');
        setInvoiceAddEditDepartment('Afdeling');
        setRows([]);
        modalRef.current.openModal();
    };

    const openEditInvoiceModal = async () => {
        setIsEditMode(true);
        setDescription('');
        setAntal(0);
        setPrice('');
        setInvoiceAddEditDepartment(selectedInvoice.department);
        setRows(selectedInvoice.items);
        modalRef.current.openModal();
    };

    return (
        <div className="main_inner" id="invfix">
            <Header title={"Fakturaer"}>
                <div className="data_container">
                    <div className="search_container" id="cssfix">
                        <div className="invoices_header_start">
                            <p>Fra:</p>
                            <DatePicker
                                className="date_input"
                                selected={startDate}
                                onChange={(date) => handleDateChange(date, 'start')}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className="invoices_header_end">
                            <p>Til:</p>
                            <DatePicker
                                className="date_input"
                                selected={endDate}
                                onChange={(date) => handleDateChange(date, 'end')}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <Dropdown
                            init={'Alle'}
                            options={['Alle', ...departmentNames]}
                            style={{ width: "150px" }}
                            onSelect={(department) => {
                                setSelectedDepartment(department);
                            }}
                        />
                    </div>
                    <div className="search_container" style={{ marginLeft: "10px", height: "60px" }} onClick={openInvoiceModal}>
                        <SearchStyleButton title={"Tilføj Faktura"} />
                    </div>
                </div>
                <div className="data_container">
                    <div className="invoice_current_header">
                        <span>Faktura ID:</span>
                        <p>{selectedInvoice ? selectedInvoice._id : ''}</p>
                    </div>
                    <div className="invoice_current_footer">
                        <span>Afdeling:</span>
                        <p>{selectedInvoice ? selectedInvoice.department : ''}</p>
                    </div>
                </div>
            </Header>
            <div className="main_inner_content" id="invoiceFix">
                <SplitInner>
                    <div className="invoices_left" ref={invoicesLeftRef}>
                        <Excel columns={columnsLeft} rows={rowsLeft} onRowClick={handleRowClick} />
                    </div>
                    {selectedInvoice ? (
                        <div className="invoices_right add_rep_invoice_bg" ref={addRepInvoiceRef}>
                            <div className="invoices_right_top">
                                <h3 className="motorcycleReg">{selectedInvoice.motorcycleReg ? `Reg. nr: ${selectedInvoice.motorcycleReg}` : ""}</h3>
                                <div className='editIcon'>
                                    <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        style={{ width: "20px", height: "20px" }}
                                        onClick={openEditInvoiceModal}
                                    />
                                </div>
                            </div>
                            <Excel columns={columnsRight} rows={rowsRight} />
                            <div className="invoices_right_data" ref={invoicesRightDataRef}>
                                <div className="invoices_right_data_buttons">
                                    <button onClick={exportInvoice}><span>EKSPORT FAKTURA</span></button>
                                    <button onClick={() => {
                                        setInvoiceToDelete(invoiceNumber.toString().slice(-6));
                                        sletFakturaModalRef.current.openModal();
                                    }}><span>SLET FAKTURA</span></button>
                                </div>
                                <div className="accums_container">
                                    <div className="invoices_accums_text" ref={accumsTextRef}>
                                        <span>Total eks. moms:</span>
                                        <span>Moms:</span>
                                        <span>Total:</span>
                                    </div>
                                    <div className="invoices_accums_actual" ref={accumsActualRef}>
                                        <NumberFormat value={(selectedInvoice.total_ex_vat || 0).toString().replace(/\./g, ',')} displayType={'text'}
                                            thousandSeparator={'.'} decimalSeparator={','}
                                            prefix={'DKK '} />
                                        <NumberFormat value={(selectedInvoice.vat || 0).toString().replace(/\./g, ',')} displayType={'text'}
                                            thousandSeparator={'.'} decimalSeparator={','}
                                            prefix={'DKK '} />
                                        <NumberFormat value={(selectedInvoice.total_incl_vat || 0).toString().replace(/\./g, ',')} displayType={'text'}
                                            thousandSeparator={'.'} decimalSeparator={','}
                                            prefix={'DKK '} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="split_inner_container_split">
                            <h2 style={{ textAlign: 'center' }} id="altinv">Vælg Faktura</h2>
                        </div>
                    )}
                </SplitInner>
            </div>
            <Modal ref={sletFakturaModalRef} title={"Er du sikker på sletning af " + invoiceToDelete + "?"}>
                <div id="modal_inner_fix">
                    <div>
                        <div className='reperation_submit_container'>
                            <button
                                onClick={deleteInvoice}
                                style={{ color: "rgb(178, 53, 53)" }}
                            >SLET FAKTURA</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal ref={modalRef} title={isEditMode ? 'Opdatér Faktura' : 'Tilføj Faktura'}>
                <div className="add_rep_container">
                    <div className="add_rep_left">
                        <div className="add_rep_header"><span>Diverse dele</span></div>
                        <div className="add_rep_desc">
                            <span>Beskrivelse</span>
                            <input type="text" value={description} onChange={e => setDescription(e.target.value)}
                                placeholder="Skriv beskrivelse..." className="search_input" />
                        </div>
                        <div className="add_rep_price">
                            <div className="add_rep_price_input">
                                <span>Pris</span>
                                <NumberFormat
                                    value={price}
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPrice(value);
                                    }}
                                    placeholder="Skriv stk. pris..."
                                    className="search_input"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    prefix={'DKK '}
                                    allowNegative={false}
                                    isNumericString={true}
                                />
                            </div>
                            <div className="add_rep_price_number">
                                <span>Antal</span>
                                <div id="hoverfix" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        value={antal}
                                        onChange={(e) => setAntal(parseInt(e.target.value) || 0)}
                                        className="search_input"
                                        placeholder="0"
                                    />
                                    <button className="search_input" onClick={() => setAntal(antal + 1)}>+</button>
                                    <button className="search_input" onClick={() => setAntal(antal - 1)}>-</button>
                                </div>
                            </div>
                            <div className="add_rep_price_interact">
                                <button onClick={handleAddRow}>TILFØJ ELEMENT</button>
                            </div>
                        </div>
                        <div className="add_rep_invoice">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Stk.</th>
                                        <th>Beskrivelse</th>
                                        <th>Stk. pris</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={index}>
                                            <td><FontAwesomeIcon className="add_rep_delete_item_invoice" icon={faXmark}
                                                onClick={() => handleRemoveRow(index)}
                                                style={{ cursor: 'pointer' }} /><p>{row.quantity}</p></td>
                                            <td>{row.description}</td>
                                            <td>
                                                <NumberFormat value={row.unit_price} displayType={'text'}
                                                    thousandSeparator={'.'} decimalSeparator={','}
                                                    prefix={'DKK '} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="add_rep_right">
                        <div className="add_rep_header"><span>Basis dele</span></div>
                        <div className="add_rep_right_basis" style={{ maxHeight: "95%" }}>
                            {baseParts.map((part, index) => {
                                const initialQuantity = selectedInvoice && selectedInvoice.items
                                    ? selectedInvoice.items.find(item => item.description === part.description)?.quantity || 0
                                    : 0;
                                return (
                                    <div key={index} className="right_basis_item">
                                        <p>{part.description}</p>
                                        <QuantBox
                                            update={(newQuantity) => updateRow(newQuantity, part.description, part.price)}
                                            initialValue={isEditMode ? initialQuantity : undefined}
                                        />
                                    </div>
                                );
                            })}


                        </div>
                        <Dropdown
                            init={invoiceAddEditDepartment}
                            options={departmentNames}
                            style={{ width: "150px" }}
                            onSelect={(department) => {
                                setInvoiceAddEditDepartment(department);
                            }}
                        />
                    </div>
                </div>
                <div className="reperation_submit_container">
                    <button onClick={handleAddInvoice}>{isEditMode ? 'Opdatér Faktura' : 'Tilføj Faktura'}</button>
                </div>
            </Modal>
        </div>
    );
}

export default Invoices;