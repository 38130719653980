import React, { useState, useEffect } from 'react';

const QuantBox = ({ update, initialValue }) => {
    const [amount, setAmount] = useState(initialValue || 0);

    useEffect(() => {
        setAmount(initialValue || 0);
    }, [initialValue]);

    const handleChange = (e) => {
        let value = parseInt(e.target.value, 10);
        if (isNaN(value) || value < 0) {
            value = 0;
        }
        setAmount(value);
        update(value);
    };

    const increment = () => {
        const newAmount = amount + 1;
        setAmount(newAmount);
        update(newAmount);
    };

    const decrement = () => {
        const newAmount = amount > 0 ? amount - 1 : 0;
        setAmount(newAmount);
        update(newAmount);
    };

    return (
        <div id="hoverfix" style={{display: 'flex', alignItems: 'center'}}>
            <input
                type="text"
                value={amount}
                onChange={handleChange}
                className="search_input"
                placeholder="0"
            />
            <button className="search_input" onClick={increment}>+</button>
            <button className="search_input" onClick={decrement}>-</button>
        </div>
    );
};

export default QuantBox;
